/**
 * Reset
 * 1. Body Styles
 * 2. Input Styles
 * 3. Heading Styles
 * 4. Tag Styles
 */

*,
*:after,
*:before {
  box-sizing: border-box;
}

/**
 * 1. Body Styles
 *
 * Note: avoid adding too specific styles to <html> or <body>, which makes the
 * library less portable and less compatible with other components/libraries.
 */

html,
body {
  height: 100%;
}

body {
  margin: 0;

  /**
   * Prevent "centering jumps" when there is enough content loaded in the page
   * to have a vertical scroll
   */
  overflow-y: scroll;
  padding: 0;
}

/**
 * 2. Input Styles
 */

input:focus,
select:focus,
textarea:focus,
button:focus,
[tabindex]:focus {
  outline: none !important;
}

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  border-radius: 0;
  box-shadow: none;
  outline: none;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  -webkit-appearance: none; /* stylelint-disable-line */
}

button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

fieldset {
  border: none;
}

form {
  width: 100%;
}

input:focus,
textarea:focus {
  outline: none;

  &::placeholder {
    opacity: 0;
  }
}

input::-ms-clear {
  height: 0;
  width: 0;
}

/**
 * 3. Headings Styles
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0;
  padding: 0;
}

/**
 * 4. Tag Styles
 */

p {
  margin: 0;
  padding: 0;
}

i,
em {
  font-style: normal;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}
